import './MapPane.css';

import React from 'react';
import L from 'leaflet';
import { Map, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet';
import { isEqual } from 'lodash';
import 'leaflet/dist/leaflet.css';
import { DeregisterJobObserver, RegisterJobObserver, GetData } from '../Service';
import { UtilsService } from '../services/UtilsService';

import IconCar from '../assets/car.png';
import IconGreen from '../assets/greenmarker.png';
import IconRed from '../assets/redmarker.png';
import IconGrey from '../assets/greymarker.png';


const MarkerCar = new L.Icon({
    iconUrl: IconCar,
    iconSize: [42, 24],
    iconAnchor: [24, 24],
    popupAnchor: [2, -12]
});

const MarkerGreen = new L.Icon({
    iconUrl: IconGreen,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    popupAnchor: [0, -16],
    tooltipAnchor: [0, 16]
});

const MarkerRed = new L.Icon({
    iconUrl: IconRed,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    popupAnchor: [0, -16],
    tooltipAnchor: [0, 16]
});

const MarkerGrey = new L.Icon({
    iconUrl: IconGrey,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    popupAnchor: [0, -16],
    tooltipAnchor: [0, 16]
});


// interface MapPaneProps {
//     activeJobs: Job[];
// }


export class MapPane extends React.Component {

    mapRef; /* Store the reference for the Map component */
    utilsService = new UtilsService();


    constructor(props) {
        super(props);
    }


    emitCompletedJobMarkerClicked(jobNumber) {
        if (this.props?.completedJobMarkerClicked) {
            this.props.completedJobMarkerClicked(jobNumber)
        }
    }


    emitLiveJobMarkerClicked(jobNumber) {
        if (this.props?.liveJobMarkerClicked) {
            this.props.liveJobMarkerClicked(jobNumber);
        }
    }


    get drivers() {
        const driversData = [];
        
        this.props?.activeJobs?.forEach(job => {
            const driver = {
                name: job.driverDetail.driverName,
                lat: job.driverDetail.latitude,
                lng: job.driverDetail.longitude,
                id: job.driverNumber
            };

            let valid;
            if ((job.deliveryDistance || job.pickupDistance) && !(job.deliveryDistance && job.pickupDistance)) {
                valid = true;
            } else {
                valid = false;
            }
            
            const found = driversData.find(({ id }) => id === driver.id);

            // Perm vehicle override
            if (job.deliverySuburb.trim() === 'VARIOUS') {
                valid = true;
            }
    
            if (!found && valid) {
                driversData.push(driver);
            }
        })

        return driversData;
    }


    /**
     * Determine the different properties like center, maxLat, and other properties based on drivers data.
     */
    get driversCoordinateProps() {
        var box = {
            longMin: 0.0,
            longMax: 0.0,
            latMin: 0.0,
            latMax: 0.0,
            longCenter: 0.0,
            latCenter: 0.0
        };
    
        if (this.drivers?.length) {
            this.drivers.forEach((element) => {
                if (!box.longMin) {
                    box.longMin = element.lng;
                    box.longMax = element.lng;
                    box.latMin = element.lat;
                    box.latMax = element.lat;
                }
    
                if (box.longMin > element.lng) box.longMin = element.lng;
                if (box.longMax < element.lng) box.longMax = element.lng;
                if (box.latMin > element.lat) box.latMin = element.lat;
                if (box.latMax < element.lat) box.latMax = element.lat;
            });
        }
    
        box.longCenter = (box.longMin + box.longMax) / 2;
        box.latCenter = (box.latMin + box.latMax) / 2;
    
        if (box.longCenter === 0.0) box.longCenter = 144.959721;
        if (box.latCenter === 0.0) box.latCenter = -37.81569;

        return box;
    }


    getBox() {
        var box = this.driversCoordinateProps;
        return [[box.latMin, box.longMin], [box.latMax, box.longMax]];
    }


    getBoxTd = (td) => {
        if(this.isDriverLocationValid(td)) {
            return [
                [td.driver.location.lat - .2, td.driver.location.long - .2],
                [td.driver.location.lat + .2, td.driver.location.long + .2]
            ];
        }

        else {
            const stateCoordinates = this.utilsService.stateCenterCoordinates(td?.details?.stateId);
            return [
                [stateCoordinates.lat - .2, stateCoordinates.lng - .2],
                [stateCoordinates.lat + .2, stateCoordinates.lng + .2]
            ];
        }
    }


    /**
     * Map should be centered by driver's location by default.
     * But there are scenarios where driver's location is a 0,0(meaning it has invalid location) so we are going to center the map using the current state.
     */
    getInitialMapCenter(trackingDetails) {
        if(this.isDriverLocationValid(trackingDetails)) {
            return [trackingDetails?.driver?.location?.lat ?? 0, trackingDetails?.driver?.location?.long ?? 0];
        }

        else {
            const stateCoordinates = this.utilsService.stateCenterCoordinates(trackingDetails?.details?.stateId);
            return [stateCoordinates.lat, stateCoordinates.lng];
        }
    }


    /**
     * Driver's location is invalid if it is 0,0
     */
    isDriverLocationValid(trackingDetails) {
        if(trackingDetails?.driver?.location?.lat || trackingDetails?.driver?.location?.long) {
            return true;
        } else {
            return false;
        }
    }


    centerMapByCoordinates(lat, lng) {
        // const currentMapZoom = this.mapRef.leafletElement.getZoom();
        const zoomLevel = 16;
        this.mapRef.leafletElement.flyTo([lat, lng], zoomLevel);
    }


    /**
     * Will try to find the driver marker that matches the provided driverNumber then center the map to it.
     * Will do nothing if no marker matched the provided driverNumber.
     */
    centerMapByDriverNumber(driverNumber) {
        const zoomLevel = 16;
        const driver = this.drivers?.find(driver => driver.id === driverNumber);
        
        if(driver) {
            this.mapRef.leafletElement.flyTo([driver.lat, driver.lng], zoomLevel);
        }
    }


    renderLegendContainer() {
        return <div className="legend-container flex flex-column">
            <div className="current flex flex-row gap-2 align-items-center">
                <i className="pi pi-circle-on"></i>
                <div>Current Job</div>
            </div>
            <div className="live flex flex-row gap-2 align-items-center">
                <i className="pi pi-circle-on"></i>
                <div>Live Job</div>
            </div>
            <div className="completed flex flex-row gap-2 align-items-center">
                <i className="pi pi-circle-on"></i>
                <div>Completed Job</div>
            </div>            
        </div>
    }
    
    
    renderRouteCodeDetailsInMarker(leg) {
        if(leg.showRouteCode) {
            return <>
                <div className="flex flex-row gap-1"><b>Route</b><span>{leg.routeCode}</span></div>
                <div className="flex flex-row gap-1"><b>Drop</b><span>{leg.dropNumber}</span></div>
            </>
        }
    }


    renderDriverMarker(trackingDetails) {
        if(trackingDetails?.driver?.location?.lat || trackingDetails?.driver?.location?.long) {
            return <Marker position={[trackingDetails.driver.location.lat, trackingDetails.driver.location.long]} icon={MarkerCar} key="1">
                <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>
                    {trackingDetails.driver.name} #{trackingDetails.driver.id}
                </Tooltip>
            </Marker>
        }
    }


    render() {
        const td = this.props.trackingDetails;

        if (td) {
            return (
                <div className="map-pane-component" style={{ height: '100%' }}>
                    {this.renderLegendContainer()}
                    <Map ref={(e) => this.mapRef = e} center={this.getInitialMapCenter(td)} bounds={this.getBoxTd(td)}>
                        <TileLayer
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                            url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                        />

                        {td.driver.incompleteLegs.map((leg, index) => (
                            <Marker position={[leg.lat, leg.long]} icon={MarkerRed} key={index} onClick={() => this.emitLiveJobMarkerClicked(leg.iLogixJobNumber)}>
                                <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>
                                    <div className="flex flex-row gap-1"><b>Job#</b><span>{leg.jobNumber}</span></div>
                                    {/*<div className="flex flex-row gap-2"><b>Ref1</b><span>{leg.ref1}</span></div>*/}
                                    {/*<div className="flex flex-row gap-2"><b>Ref2</b><span>{leg.ref2}</span></div>*/}
                                    {this.renderRouteCodeDetailsInMarker(leg)}
                                </Tooltip>
                            </Marker>
                        ))}

                        {td.driver.completeLegs.map((leg, index) => (
                            <Marker position={[leg.lat, leg.long]} icon={MarkerGrey} key={index} onClick={() => this.emitCompletedJobMarkerClicked(leg.iLogixJobNumber)}>
                                <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>
                                    <div className="flex flex-row gap-1"><b>Job#</b><span>{leg.jobNumber}</span></div>
                                    {/*<div className="flex flex-row gap-2"><b>Ref1</b><span>{leg.ref1}</span></div>*/}
                                    {/*<div className="flex flex-row gap-2"><b>Ref2</b><span>{leg.ref2}</span></div>*/}
                                    {this.renderRouteCodeDetailsInMarker(leg)}
                                </Tooltip>
                            </Marker>
                        ))}

                        {this.renderDriverMarker(td)}

                        {td.legs.map((leg, index) => (
                            <Marker position={[leg.location.lat, leg.location.long]} icon={MarkerGreen} key={index}>
                                <Popup>
                                    <div>
                                        <b>{leg.address}</b>
                                    </div>
                                </Popup>
                                <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>
                                    {leg.legType}
                                </Tooltip>
                            </Marker>
                        ))}
                    </Map>
                </div>
            );
        }


        else {
            const position = [this.driversCoordinateProps?.lat, this.driversCoordinateProps?.lng];
            return (
                <div className="map-pane-component" style={{ height: '100%' }}>
                    <Map ref={(e) => this.mapRef = e} center={position} bounds={this.getBox()}>
                        <TileLayer
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                            url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                        />
                        {this.drivers.map((driver, index) => (
                            <Marker position={[driver.lat, driver.lng]} icon={MarkerCar} key={index}>
                                <Popup>
                                    <div>
                                        <b>{driver.name}</b>
                                    </div>
                                </Popup>
                                <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>
                                    #{driver.id}
                                </Tooltip>
                            </Marker>
                        ))}
                    </Map>
                    {/* {this.drivers?.map((driver, index) => <h1 key={index}>{driver.id}</h1>)} */}
                </div>
            );
        }
    }
}