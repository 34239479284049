import React, { Component } from 'react';
import {
    Container,
    Table,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardTitle, FormGroup, Form, Label,
    Spinner
} from 'reactstrap';

import { BsCheck, BsEnvelope } from "react-icons/bs";

import ManagerContext from '../context/ManagerContext';
import AsnConnect from '../module/AsnConnector';
import { JobRow } from '../components/asn/JobRow';


var DatePicker = require("reactstrap-date-picker");


function filterState(client, stateId) {
    return client.stateId === stateId;
}


export class Asn extends Component {
    static displayName = Asn.name;

    constructor(props,context) {
        super(props,context);

        let today = new Date();

        if (context.IsLoggedIn()) {

            let stateList = this.context.AllowedStates();
            let clients = this.context.AllowedClients().filter((data) => filterState(data, this.stateId(stateList[0])));

            this.state = {
                allowedStates: stateList,
                allowedClients: this.context.AllowedClients(),
                visibleClients: clients,
                searchFrom: today.toISOString(),
                searchTo: today.toISOString(),
                searchToChanged: false,
                jobList: [],
                selectedState: this.stateId(stateList[0]),
                selectedClient: clients[0].clientCode,
                searchReference1: '',
                allSelected: false,
                signals: [],
                sortBy: '',
                sortDesc: true
            };
        }
    }

    jobEdit = (job) => {
        AsnConnect.JobEdit(
            job
        ).then((data) => {
            if (data !== 'failure') {
                let copy = this.state.jobList;
                copy.forEach(function (item,index) {
                    if (item.id === job.id) copy[index] = job;
                });
                this.setState({jobList:copy});
            }
        });
    }
     
    stateId = (id) => {
        switch (id) {
            case 'VIC':
                return 1;
            case 'NSW':
                return 2;
            case 'QLD':
                return 3;
            case 'SA':
                return 4;
            case 'WA':
                return 5;
            default:
                return 0;
        };
    }

    updateState = (e) => {
        let clients = this.context.AllowedClients().filter((data) => filterState(data, this.stateId(e)));

        this.setState({ visibleClients: clients, selectedClient: clients[0].clientCode, selectedState: this.stateId(e), jobList: [], signals:[] });
    }

    updateClient = (e) => {
        this.setState({selectedClient: e, jobList: [],signals:[] });
    }

    updateReference1 = (e) => {
        this.setState({ searchReference1: e, jobList: [], signals:[] });
    }

    setToday = (e) => {
        e.preventDefault();

        let today = new Date();

        this.setState({ searchFrom: today.toISOString(), searchTo: today.toISOString(), searchToChanged: false, jobList: [], signals: [] });
    }

    setAll = (e) => {
        e.preventDefault();
       
        this.setState({ searchFrom: null, searchTo: null, searchToChanged: false, jobList: [] });
    }

    handleDateFromChange(value, formattedValue) {
        if (!this.state.searchToChanged) {
            this.setState({
                searchTo: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
                searchToformattedValue: formattedValue, // Formatted String, ex: "11/19/2016"
                jobList: [],
                signals: []
            })
        }
        this.setState({
            searchFrom: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
            searchFromformattedValue: formattedValue, // Formatted String, ex: "11/19/2016"
            jobList: [],
            signals: []
        })
    }

    handleDateToChange(value, formattedValue) {
        this.setState({
            searchTo: value, 
            searchToformattedValue: formattedValue,
            searchToChanged: true,
            jobList: [],
            signals: []
        })
    }

    handleSearchClick(e) {
        e.preventDefault();
        var searchButton = e.target;
        var searchSpinner = document.getElementById("SearchSpinner");

        searchSpinner.style.visibility = "visible";
        searchButton.disabled = "true";

        AsnConnect.JobList(
            this.state.selectedClient,
            this.state.selectedState,
            this.state.searchFrom,
            this.state.searchTo,
            this.state.searchReference1
        ).then((data) => {
            if (data !== 'failure') {
                this.setState({ jobList: data });
                searchButton.disabled = false;
                searchSpinner.style.visibility = "hidden";
            }
        });
    }

    handleResetClick(e) {
        e.preventDefault();

        this.setState({
            jobList: [],
            signals: []
        })
    }

    handleSaveChange = (e) => {
        this.state.signals.forEach(s => {
            s.call("Save").then(this.updateJob);
        })
    }

    handleClearChange = (e) => {
        this.state.signals.forEach(s => {
            s.call("Reset");
        })
    }

    handleChangeDriver = (e) => {
        let val = document.getElementById("driverNumber").value;

        if (val<0) {
            this.context.ToastErr("Driver must be positive");
            return;
        }

        this.state.signals.forEach(s => {
            s.call("Driver",val);
        })
    }

    handleChangeDate = (e) => {
        let date = document.getElementById("serviceDate").value;
        let time = document.getElementById("serviceTime").value;

        if (date === "" || time === "") {
            this.context.ToastErr("Both a date and time is required");
            return;
        }

        let datetime = { date: date, time: time };
        this.state.signals.forEach(s => {
            s.call("DateTime", datetime);
        })
    }

    handleChangeService = (e) => {
        let val = document.getElementById("serviceCode").value;

        if (val === "") {
            this.context.ToastErr("Service cannot be blank");
            return;
        }

        this.state.signals.forEach(s => {
            s.call("Service", val);
        })
    }

    handleBookJobs = (e) => {
        this.state.signals.forEach(s => {
            s.call("Book").then(this.clearJob);
        })
    }

    handleCancelJobs = (e) => {
        this.state.signals.forEach(s => {
            s.call("Cancel").then(this.clearJob);
        })
    }

    addSignal = (signal,id) => {
        let s = this.state.signals;
        var newSig = { call: signal, id: id };
        s.push(newSig);
        this.setState({signals:s});
    }

    checkAllRows = (e) => {
        e.preventDefault();

        var jobs = this.state.jobList;

        for (var a of jobs) {
            a.selected = !this.state.allSelected;
        }

        this.setState({ jobList: jobs, allSelected: !this.state.allSelected });
    }

    clearJob = (res) => {
        if (res.success) {
            var jobs = this.state.jobList.filter(function (value, index, arr) {
                return value.id !== res.id
            });

            var sigs = this.state.signals.filter(function (value, index, arr) {
                return value.id !== res.id
            });

            this.setState({ jobList: jobs, signals:sigs, allSelected: false });
        }
    }

    updateJob = (job) => {
        if (job !== 'Skip') {
            let jobs = this.state.jobList;

            jobs.forEach((element, index) => {
                if (element.id === job.id) {
                    jobs[index] = job;
                }
            });

            this.setState({ jobList: jobs, allSelected: false });
        }
    }

    selected = (jobId, checkValue) => {
        let jobs = this.state.jobList;

        jobs.forEach((element, index) => {
            if (element.id === jobId) {
                jobs[index].selected = checkValue;
            }
        });

        this.setState({jobList: jobs, allSelected: false});
    }

    setSort = (e, sortColumn) => {
        console.log('Starting Sort with ' + sortColumn);
        var jobs = [];



        switch (sortColumn) {
            case 'caller':
                jobs = this.state.jobList.sort((a, b) => {
                    if (this.state.sortDesc) { var x = a; a = b; b = x; }
                    return (a.caller > b.caller) ? 1 : ((b.caller > a.caller) ? -1 : 0);
                })
                break;
            case 'reference1':
                jobs = this.state.jobList.sort((a, b) => {
                    if (this.state.sortDesc) { var x = a; a = b; b = x; }
                    return (a.reference1 > b.reference1) ? 1 : ((b.reference1 > a.reference1) ? -1 : 0);
                })
                break;
            case 'reference2':
                jobs = this.state.jobList.sort((a, b) => {
                    if (this.state.sortDesc) { var x = a; a = b; b = x; }
                    return (a.reference2 > b.reference2) ? 1 : ((b.reference2 > a.reference2) ? -1 : 0);
                })
                break;
            case 'bookedOn':
                jobs = this.state.jobList.sort((a, b) => {
                    if (this.state.sortDesc) { var x = a; a = b; b = x; }
                    return (a.bookedOn > b.bookedOn) ? 1 : ((b.bookedOn > a.bookedOn) ? -1 : 0);
                })
                break;
            case 'senderSuburb':
                jobs = this.state.jobList.sort((a, b) => {
                    if (this.state.sortDesc) { var x = a; a = b; b = x; }
                    return (a.pickupSuburb > b.pickupSuburb) ? 1 : ((b.pickupSuburb > a.pickupSuburb) ? -1 : 0);
                })
                break;
            case 'senderPostcode':
                jobs = this.state.jobList.sort((a, b) => {
                    if (this.state.sortDesc) { var x = a; a = b; b = x; }
                    return (a.pickupPostcode > b.pickupPostcode) ? 1 : ((b.pickupPostcode > a.pickupPostcode) ? -1 : 0);
                })
                break;
            case 'receiverSuburb':
                jobs = this.state.jobList.sort((a, b) => {
                    if (this.state.sortDesc) { var x = a; a = b; b = x; }
                    return (a.deliverySuburb > b.deliverySuburb) ? 1 : ((b.deliverySuburb > a.deliverySuburb) ? -1 : 0);
                })
                break;
            case 'receiverPostcode':
                jobs = this.state.jobList.sort((a, b) => {
                    if (this.state.sortDesc) { var x = a; a = b; b = x; }
                    return (a.deliveryPostcode > b.deliveryPostcode) ? 1 : ((b.deliveryPostcode > a.deliveryPostcode) ? -1 : 0);
                })
                break;
            case 'driver':
                jobs = this.state.jobList.sort((a, b) => {
                    if (this.state.sortDesc) { var x = a; a = b; b = x; }
                    return (a.driver > b.driver) ? 1 : ((b.driver > a.driver) ? -1 : 0);
                })
                break;
            case 'readyFor':
                jobs = this.state.jobList.sort((a, b) => {
                    if (this.state.sortDesc) { var x = a; a = b; b = x; }
                    return (a.readyFor > b.readyFor) ? 1 : ((b.readyFor > a.readyFor) ? -1 : 0);
                })
                break;
            default:
                jobs = this.state.JobList;
        }

        var sort = !this.state.sortDesc;
    
        this.setState({ jobList: jobs, sortBy: sortColumn, sortDesc:sort });
    }

    stateName = (name) => {
        switch (name) {
            case 1:
                return 'VIC';
            case 2:
                return 'NSW';
            case 3:
                return 'QLD';
            case 4:
                return 'SA';
            case 5:
                return 'WA';
            default:
                return 0;
        };
    }

    render() {
        let context = this.context;

        if (context.IsLoggedIn() && context.IsAsnUser()) {
            if (this.state != null) {

                return (

                    <Container fluid>
                        <Card body className="text-center">
                            <CardTitle tag={'h3'}>Advanced Shipping Notice</CardTitle>
                        </Card>
                        <br />
                        <Card body>
                            <Form>
                                <Row>
                                    <Col sm={5} >
                                        <FormGroup row>
                                            <Label sm={5} for="stateSelect">State</Label>
                                            <Col sm={7}>
                                                <Input type="select" name="select" id="stateSelect" onChange={(e) => this.updateState(`${e.target.value}`)}>
                                                    {this.state.allowedStates.map(state => (
                                                        <option key={state}>{state}</option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={5} for="accountSelect">Account Code</Label>
                                            <Col sm={7}><Input type="select" name="select" id="accountSelect" onChange={(e) => this.updateClient(`${e.target.value}`)}>

                                                {this.state.visibleClients.map(client => (
                                                    <option key={client.clientCode}>{client.clientCode}</option>
                                                ))}

                                            </Input></Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={5} for="accountSelect">Reference 1</Label>
                                            <Col sm={7}>
                                                <Input type="text" name="select" id="accountSelect" onChange={(e) => this.updateReference1(`${e.target.value}`)} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={5} >
                                        <FormGroup row>
                                            <Label sm={5} for="searchFromDate">Search From</Label>
                                            <Col sm={7}><DatePicker
                                                type="date"
                                                name="date"
                                                id="searchFromDate"
                                                value={this.state.searchFrom}
                                                onChange={(v, f) => this.handleDateFromChange(v,f)}
                                                placeholder="Search From" /></Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={5} for="searchToDate">Search Until</Label>
                                            <Col sm={7}>
                                                <DatePicker
                                                    type="date"
                                                    name="date"
                                                    id="searchToDate"
                                                    value={this.state.searchTo}
                                                    onChange={(v, f) => this.handleDateToChange(v,f)}
                                                    placeholder="Search Until" /></Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <Row>
                                            <Col sm={6}>
                                                <Button block onClick={(e) => this.setToday(e)}>Today</Button>
                                            </Col>
                                            <Col sm={6}>
                                                <Button block onClick={(e) => this.setAll(e)}>All</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                
                                                <Button variant="primary" id="SearchButton" style={{ 'marginTop': '17px' }} onClick={(e) => this.handleSearchClick(e)} block>
                                                    <Spinner style={{ 'visibility': 'hidden','float':'right' }} id="SearchSpinner" as="span" variant="warning" size="sm" role="status" aria-hidden="true" animation="grow" />
                                                    &nbsp;Search
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>

                                                <Button id="clearResults" style={{ 'marginTop': '17px' }} onClick={(e) => this.handleResetClick(e)} block>
                                                    Reset
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                        <br />
                        <Card body>
                            <CardTitle className="text-center" tag={'h4'}>Booking List - ({this.state.jobList.length}) Jobs</CardTitle>

                            <Row>
                                <Col sm={7}>
                                    <Row>
                                        <Col sm={3}><Button color="success" block size="sm" onClick={(e) => this.handleBookJobs(e)}>Release Selected</Button></Col>
                                        <Col sm={3}><Button block size="sm" onClick={(e) => this.handleSaveChange(e)}>Save All Changes</Button></Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col sm={3}><Button color="warning" block size="sm" onClick={(e) => this.handleCancelJobs(e)}>Cancel Selected</Button></Col>
                                        <Col sm={3}><Button block size="sm" onClick={(e) => this.handleClearChange(e)}>Clear All Changes</Button></Col>
                                    </Row>
                                </Col>
                                <Col sm={3}>
                                    <Row>
                                        <Col sm={7}><Button block size="sm" onClick={(e) => this.handleChangeDriver(e)}>Assign Driver to Selected</Button></Col>
                                        <Col sm={3}><Input block type="number" size="sm" id="driverNumber" /></Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col sm={7}><Button block size="sm" onClick={(e) => this.handleChangeService(e)}>Assign Service to Selected</Button></Col>
                                        <Col sm={3}><Input block type="text" size="sm" id="serviceCode" /></Col>
                                    </Row>
                                </Col>
                                <Col sm={2}>
                                    <Row>
                                        <Col sm={12}><Button block size="sm" onClick={(e) => this.handleChangeDate(e)}>Assign Advance Date to Selected</Button></Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col sm={6}><Input block type="date" size="sm" id="serviceDate" /></Col>
                                        <Col sm={6}><Input block type="time" size="sm" id="serviceTime" /></Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Card>


                        <Table block="true" striped hover bordered bsSize="sm">
                            <thead className="text-center table-info">
                                <tr>
                                    <th onClick={this.checkAllRows}><BsCheck /></th>

                                    {context.User.allowHds &&
                                        <th><BsEnvelope /></th>}
                                    <th onClick={(e) => this.setSort(e,'caller')}>Caller</th>
                                    <th onClick={(e) => this.setSort(e,'reference1')}>Reference 1</th>
                                    <th onClick={(e) => this.setSort(e,'reference2')}>Reference 2</th>
                                    <th>Contact #</th>

                                    <th onClick={(e) => this.setSort(e,'bookedOn')}>Created On</th>

                                    <th>Sender Name</th>
                                    <th>Pickup Address 1</th>
                                    <th>Pickup Address 2</th>
                                    <th onClick={(e) => this.setSort(e,'senderSuburb')}>Pickup Suburb</th>
                                    <th onClick={(e) => this.setSort(e,'senderPostcode')}>Pickup Postcode</th>

                                    <th>Receiver Name</th>
                                    <th>Delivery Address 1</th>
                                    <th>Delivery Address 2</th>
                                    <th onClick={(e) => this.setSort(e,'receiverSuburb')}>Delivery Suburb</th>
                                    <th onClick={(e) => this.setSort(e,'receiverPostcode')}>Delivery Postcode</th>

                                    <th onClick={(e) => this.setSort(e,'driver')}>Driver</th>
                                    <th>Svc</th>
                                    <th>Items</th>
                                    <th onClick={(e) => this.setSort(e,'readyFor')}>Ready For</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {
                                    this.state.jobList.map(job => (
                                        <JobRow key={job.id} addSignal={this.addSignal} editor={this.jobEdit} selected={this.selected} job={job} stateAbbrev={this.stateName(this.state.selectedState)} />
                                ))}
                                
                            </tbody>
                        </Table>


                    </Container>
                );
            } else {
                return (
                    <Container fluid>
                        Please wait while we load this page for you...
                    </Container>
                );
            }
        } else {
            return (
                <Container fluid>
                    <p>Please ensure that you are logged in and have access to this resource. If you believe that this is an error please contact your account manager</p>
                </Container>
            );
        }
     
    }
}

Asn.contextType = ManagerContext;
