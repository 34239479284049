
import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Input, Spinner } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import ManagerContext from '../context/ManagerContext';
import { JobProgressIndicator } from './JobProgressIndicator';
import { UtilsService } from '../services/UtilsService';
import { ApiService } from '../services/ApiService';

import './ActiveJobs.css';


// interface ActiveJobsProps {
//     activeJobs: Job[];
//     loadingActiveJobs: boolean;
//     jobSelected: Job;
// }



export class ActiveJobs extends Component {

    utilsService = new UtilsService();
    apiService = new ApiService();
    displayName = ActiveJobs.name;
    activeJobsTable;
    

    constructor(props, context) {
        super(props, context);

        this.state = {
            columns: this.generateColumns([]), 
            context: context,
            sortField: 'pickupDistance',
            sortOrder: -1
        };
    }


    /**
     * Driver column is using custom filter function.
     * Run this filter whenever the user typed into the Driver column filter.
     * Also sort the active jobs by Driver column at the same time.
     */
    filterByDriverNumber(filterValue) {
        this.activeJobsTable.filter(filterValue, 'driverNumber', 'contains');
        this.activeJobsTable.setState({ sortField: 'driverNumber', sortOrder: 1 });
        this.setState({ sortField: 'driverNumber', sortOrder: 1 });
    }


    generateColumns(jobs) {
        const showProgress = (jobs?.find(job => job.showProgress)) ? true : false; /* Consider showProgress as true if at least one of the jobs has showProgress:true */
        const showRouteCode = (jobs?.find(job => job.showRouteCode)) ? true : false; /* Consider showRouteCode as true if at least one of the jobs has showRouteCode:true */
        const showReplayLink = (jobs?.find(job => job.showReplay)) ? true : false; /* Display an extra column to contain the "Replay" link. Do no add the extra column if none of the jobs has showReplay: true */

        const columns = [
            { 
                field: 'routeCode', header: 'Route Code', sortable: true, hidden: showRouteCode ? false : true, filter: true, 
                body: (row) => <span title={row.routeCode}>{row.routeCode}</span> 
            },
            {
                field: 'dropNumber', header: 'Drop Number', sortable: true, hidden: showRouteCode ? false : true,
                body: (row) => <span title={row.dropNumber}>{row.dropNumber}</span>
            },
            { 
                field: 'driverNumber', header: 'Driver', sortable: true, filter: true,
                filterElement: <Input onKeyDown={(e) => this.selectDriver(e)} onChange={(e) => this.filterByDriverNumber(e.target?.value)} />,
                body: (row) => <span title={row.driverNumber}>{row.driverNumber}</span>
            },
            {
                field: 'driverDetail.driverName', header: 'Driver Name', sortable: true,
                body: (row) => <span title={row.driverDetail?.driverName}>{row.driverDetail?.driverName}</span>
            },
            {
                field: 'stateAbbrev', header: 'State',
                body: (row) => <span title={row.stateAbbrev}>{row.stateAbbrev}</span>
            },
            { 
                field: 'ref1', header: 'Ref 1', sortable: true, filter: true,
                body: (row) => <span title={row.ref1}>{row.ref1}</span>
            },
            { 
                field: 'ref2', header: 'Ref 2', sortable: true, filter: true,
                body: (row) => <span title={row.ref2}>{row.ref2}</span>
            },
            { 
                field: 'shortJobNumber', header: 'Job Number', sortable: true,
                body: (row) => <span title={row.shortJobNumber}>{row.shortJobNumber}</span>
            },
            { 
                field: 'serviceCode', header: 'Service Code', sortable: true,
                body: (row) => <span title={row.serviceCode}>{row.serviceCode}</span>
            },
            { 
                field: 'deliverySuburb', header: 'Deliveries', sortable: true, filter: true,
                body: (row) => <span title={row.deliverySuburb}>{row.deliverySuburb}</span>
            },
            {
                field: 'status', header: 'Status', sortable: true,
                body: (row) => <span title={row.status}>{row.status}</span>
            },
            { 
                field: 'pickupDistance', header: 'KM to P/U', sortable: true,
                body: (row) => <span title={row.pickupDistance}>{row.pickupDistance}</span>
            },
            {
                field: 'deliveryDistance', header: 'KM to Del', sortable: true,
                body: (row) => <span title={row.deliveryDistance}>{row.deliveryDistance}</span>
            },
            {
                field: 'etd', header: 'ETD (mins)', sortable: true,
                body: (row) => <span title={row.etd}>{row.etd}</span>
            },
            { 
                field: 'progress', header: 'Estimated Progress',
                hidden: showProgress ? false : true,
                body: (row, column) => {
                  if(row.showEstimatedProgress) {
                    return <JobProgressIndicator progress={row.estimatedProgress ?? 0}/>
                  }  
                }
            },
            { 
                field: '', header: '', 
                hidden: showReplayLink ? false : true,
                body: (row, column) => {
                    if(row.showReplay) {
                        return <div onClick={(e) => e.stopPropagation()}>
                            <LinkContainer to={'/historical-replay/'+row.clientCode+'/'+row.driverNumber+'/'+row.stateAbbrev+'/'+row.modified}>
                                <Button label="Replay" className="p-button-link text-xs" />
                            </LinkContainer>
                        </div>
                    }
                }
            }
        ];

        return columns;
    }


    renderActiveJobsTable() {
        const viewIsSmallerThanLaptop = window.innerWidth <= 1200;
        const tableColumns = this.generateColumns(this.props?.activeJobs ?? []);
        
        if(this.props?.loadingActiveJobs) {
            return <div className="flex flex-row align-items-center gap-2 p-2">
                <Spinner color="info" />
                <em>Please wait while we retrieve your current active jobs.</em>
            </div>
        } else if(this.props?.activeJobs?.length === 0) {
            return <p><em>No active jobs.</em></p>;
        } else {
            const filteredColumns = tableColumns?.filter(column => !column.hidden); /* Do not render columns with "hidden" flag */
            
            return <DataTable
                ref={(e) => this.activeJobsTable = e} 
                value={this.props?.activeJobs} 
                className="p-datatable-sm p-datatable-gridlines p-datatable-striped" 
                paginator 
                rows={32}
                rowsPerPageOptions={[32, 50, 100]}
                sortField={this.state.sortField}
                sortOrder={this.state.sortOrder}
                onRowClick={(e) => this.utilsService.openJobTrackingPageUsingClientCodeAndState(e.data.jobNumber, e.data.clientCode, e.data.stateAbbrev, this.context.User)}
                autoLayout={true}
                scrollable={viewIsSmallerThanLaptop ? false : true}
                scrollHeight={'calc(100vh - 280px)'}
            >
                {filteredColumns?.map((column, index) => {
                    return <Column 
                        key={index} 
                        field={column.field} 
                        header={column.header} 
                        filter={column.filter}
                        filterElement={column.filterElement}
                        filterMatchMode={'contains'}
                        sortable={column.sortable} 
                        body={column.body}
                    ></Column>
                })}
            </DataTable>
        }
    }


    /**
     * Emit "jobSelected" event and pass the driver details.
     * Execute only when the user pressed Enter key while inside the "Driver" column's text filter.
     * If there are multiple drivers in the filtered results, we pick the first driver and pass that in the "jobSelected" event.
     */
    selectDriver(keydownEvent) {
        // Execute only on pressing "Enter" key
        if(keydownEvent.keyCode === 13) { 
            const filters = this.activeJobsTable.getFilters();
            const results = this.activeJobsTable?.filterLocal(this.props?.activeJobs, filters);
            const sortedResults = this.activeJobsTable?.sortSingle(results, 'driverNumber', 1);
            const job = sortedResults[0];
            this.props?.jobSelected(job);
        }
    }


    render() {    
        return (
            <div className="active-jobs flex flex-column gap-5">
                {this.renderActiveJobsTable()}
            </div>
        );
    }
}

ActiveJobs.contextType = ManagerContext;